<template>
  <div class="fragment">
    <template v-if="$store.getters.getAuthTokens.length > 0 && !$store.getters.getAuthTokensLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_user',
          'authTokens_userId',
          'authTokens_token',
          'authTokens_createdAt',
          'authTokens_restore',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('authTokens_userId.localization_value.value')}}</th>
            <th>{{$t('authTokens_token.localization_value.value')}}</th>
            <th>{{$t('authTokens_createdAt.localization_value.value')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getAuthTokens"
              :key="index"
          >
            <td>
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'user.id'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.static_token"
              />
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
            </td>
            <td>
              <div class="table-row">
                <div class="table-right table-manage-list table-manage-list--small">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                                 class="right"
                  >
                    <template v-if="item.deleted_at === null" slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="$emit('updateItem', item.id, true)"
                      />
                    </template>
                    <template v-else slot="item">
                      <LinkButton
                          :value="$t('authTokens_restore.localization_value.value')"
                          :type="'return'"
                          @click.native="$emit('updateItem', item.id, false)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getAuthTokens"
                 :key="index"
            >
              <AuthTokensTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  @updateItem="(id, status) => {$emit('updateItem', id, status)}"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getAuthTokensCommonList.next_page_url !== null && $store.getters.getAuthTokens.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getAuthTokensCommonList.total - $store.getters.getAuthTokensForPage * $store.getters.getAuthTokensCommonList.current_page < $store.getters.getAuthTokensForPage ?
                  $store.getters.getAuthTokensCommonList.total - $store.getters.getAuthTokensForPage * $store.getters.getAuthTokensCommonList.current_page:
                  $store.getters.getAuthTokensForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getAuthTokensLoading === false && $store.getters.getAuthTokens.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import AuthTokensTableMobile from "./AuthTokensTableMobile/AuthTokensTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";


  export default {
    name: "NewPaymentsTable",

    components: {
      LinkButton,
      ManagerButton,
      ValueHelper,
      TableUserColumn,
      NoResult,
      AuthTokensTableMobile,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,
      }
    },

    methods: {

    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>
