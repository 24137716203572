<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['authTokens_generate',])"></div>
        {{$t('authTokens_generate.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content d-flex align-items-center">
        <div class="order-create__section" style="width: 100%;">
          <div class="custom-row">
            <div class="custom-col">
              <UserSelect
                  :validationUser="User.validation.userId"
                  :validationTxtUser="User.validationTxt.userId"
                  :validationTranslateUser="User.validationTranslate.userId"
                  :userSelected="User.user"
                  :typeSelect="'users'"
                  :userEmail="true"
                  @changeUser="changeUser"
              />
            </div>
          </div>

        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="custom-popup__btn">
        <MainButton
            class="custom-popup__btn-i wfc"
            :value="$t('authTokens_generate.localization_value.value')"
            @click.native="generateAuthToken"
        />
      </div>
    </template>

  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import {User} from "@/components/globalModels/User";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
export default {
  name: "GenerateAuthTokenPopup",
  components: {MainButton, UserSelect, Modal},

  data() {
    return {
      User: new User(),
    }
  },

  methods: {
    changeUser(user) {
      this.User.setUser(user)
    },

    generateAuthToken() {

      let valid = this.User.userValidation({userId: true})

      if(!valid) return

      this.$store.dispatch('generateAuthTokens', {user_id: this.User.getUserId()}).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.$emit('reload')
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },
  }

}
</script>

<style scoped>

</style>