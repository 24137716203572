<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small"
          >
            <ValueHelper
                :value="item"
                :deep="'user.id'"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                    v-if="isAdmin"
                    :item="item"
                    :mobile="true"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('authTokens_token.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item"
                    :deep="'static_token'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('authTokens_createdAt.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
            <template v-if="item.deleted_at === null">
              <LinkButton
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('updateItem', item.id, true)"
              />
            </template>
            <template v-else>
              <LinkButton
                  :value="'Restore'"
                  :type="'return'"
                  @click.native="$emit('updateItem', item.id, false)"
              />
            </template>
          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "ConsolidationUnionTableMobile",

    components: {
      LinkButton,
      TableUserColumn,
      ValueHelper,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }
</style>
