<template>
  <AuthTokensTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @updateItem="updateItem"
  />
</template>

<script>
  import AuthTokensTableAdmin from "./AuthTokensTableAdmin/AuthTokensTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "AuthTokensTable",

    components: {
      AuthTokensTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getAuthTokensForPage,
        page: 1,
        onePage: 1,

        filterToken: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        // this.activeTabByPermissions('navTabs', {
        //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })
        //
        // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        //
        // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
        //   this.setActivePermissionVisibleTab('navTabs')
        //   return
        // }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          token: this.$route.query.token,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setAuthTokensFilter', window.location.search)

        if (next) this.$store.commit('setNextAuthTokensPage', true)
        this.$store.dispatch('fetchAuthTokens', url).then(() => {
          this.$store.commit('setNextAuthTokensPage', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'OauthStaticTokenUserId',
              filterToken: 'OauthStaticTokenStaticToken',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'betweenCreatedAtQuery',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      updateItem(id, status) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let data = {
          'remove': status
        }

        let confirm = () => {

          this.$store.dispatch('updateAuthTokens', {id: id, data: data}).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped>

</style>