<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          '',
        ])"></div>

      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">

      <div class="btn-right-block">
        <div
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['authTokens_generate'])"></div>
          <MainButton class="btn-fit-content ml-3"
                      :value="$t('authTokens_generate.localization_value.value')"
                      @click.native="isModalGenerateAuthTokenPopup = true"
          />
        </div>
      </div>
    </div>

<!--    <div class="light-bg-block">-->
<!--      <div class="btn-left-block ">-->
<!--&lt;!&ndash;        <div class="btn-left-block-i">&ndash;&gt;-->
<!--&lt;!&ndash;          <DefaultCheckbox&ndash;&gt;-->
<!--&lt;!&ndash;              :label="$t('common_selectAll.localization_value.value')"&ndash;&gt;-->
<!--&lt;!&ndash;              @input="(val) => $emit('selectAll', val)"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->

<!--      <div class="btn-right-block">-->

<!--      </div>-->
<!--    </div>-->

    <GenerateAuthTokenPopup
        v-if="isModalGenerateAuthTokenPopup"
        @close="isModalGenerateAuthTokenPopup = false"
    />
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import GenerateAuthTokenPopup
  from "@/components/modules/AuthTokensModule/popups/GenerateAuthTokenPopup/GenerateAuthTokenPopup";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


export default {
  name: "AuthTokensHead",
  components: {
    MainButton,
    GenerateAuthTokenPopup,
    ToggleFilterButton,
    // DefaultCheckbox,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {
      isModalGenerateAuthTokenPopup: false,
    }
  },

  created() {

  },

  methods: {

    selectAll(val) {
      this.$emit('selectAll', val)
    },

  }
}
</script>

<style scoped>
@media (min-width: 680px) {
  .btn-left-block{
    flex-wrap: wrap;
    max-width: 50%;
  }

  .btn-left-block-i{
    margin-left: 0!important;
    margin-right: 15px;
  }
}

@media (max-width: 680px){
  .btn-right-block{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .btn-right-block .btn-right-block-i{
    /*margin-top: 15px;*/
    margin-right: 0!important;
  }

  .btn-right-block .btn-right-block-i:last-child{
    margin-top: 0;
  }

  .btn-left-block{
    display: flex;
    flex-direction: column;
    max-width: 50%
  }

  .btn-left-block-i{
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .btn-left-block-i:last-child{
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-left-block-i--link{
    font-size: 12px;
  }

  .light-bg-block{
    align-items: flex-end;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}
</style>

